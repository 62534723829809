import { combineReducers, Reducer } from "redux";
//cli_import_section
import ProtectionUserReducer from './reducers/ProtectionUserReducer';
import CollectionReducer from "./reducers/CollectionReducer";
import { ApplicationState } from "./types";
import InternationalisationReducer from "./reducers/InternationalisationReducer";
import AccountReducer from "./reducers/AccountReducer";
import UsersReducer from "./reducers/UsersReducer";
import ServicesReducer from "./reducers/ServicesReducer";
import CategoryReducer from "./reducers/CategoryReducer";
import SummaryStatisticReducer from "./reducers/SummaryStatisticReducer";
import SummaryMonthStatisticReducer from "./reducers/SummaryStatisticMonthReducer";
import SummaryMonthRequestStatisticReducer from "./reducers/SummaryStatisticRequestMonthReducer";
import RequestServiceReducer from "./reducers/RequestServiceReducer";
import RequestByCItyStatsReducer from "./reducers/RequestBycityReducer";
import StatisticsReducer from "./reducers/StatisticsReducer";
import InstitutionsReducer from "./reducers/InstitutionsReducer";

const rootReducer: Reducer<ApplicationState> =
  combineReducers<ApplicationState>({
    //cli_reducer_section
	protectionuser : ProtectionUserReducer,
    collection: CollectionReducer,
    account: AccountReducer,
    i18n: InternationalisationReducer,
    users: UsersReducer,
    services: ServicesReducer,
    category: CategoryReducer,
    service_request: RequestServiceReducer,
    summaryStatistic: SummaryStatisticReducer,
    summaryMonthStatistic: SummaryMonthStatisticReducer,
    summaryMonthRequestStatistic: SummaryMonthRequestStatisticReducer,
    requestByCityStatistic: RequestByCItyStatsReducer,
    statistics: StatisticsReducer,
    institutions: InstitutionsReducer,
  });

export default rootReducer;
