import { ThunkAction } from "redux-thunk";
import SummaryMonthRequestStatisticService from "services/api/SummaryRequestMonthStatistic";
import {fetchMonthSummaryRequestError,fetchSummaryRequestMonthSuccess } from "store/actions/SummaryStatisticRequestMonth";
import { ApplicationAction, ApplicationState, Pagination } from "store/types";
type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;


export const getMonrhRequestSummaryEffect =
  (): Effect =>
  async (dispatch, getState) => {
    const account = getState().account;
    console.log("dans le statut des stats");
    return SummaryMonthRequestStatisticService.get_summary()
      .then(async (res: any) => {
        if (res.status === 200) {
          let  data = await res.json();
          localStorage.setItem("statisitRequestMonth",JSON.stringify(data))
          return dispatch(fetchSummaryRequestMonthSuccess(false,data));
        } else {
            dispatch(fetchMonthSummaryRequestError());
        }
      })
      .catch((err: any) => {
        dispatch(fetchMonthSummaryRequestError());
        console.log(err);
      });
  };
