import {AddUser, DeleteUser, Pagination, UpdateUser, User} from "store/types";
import BaseService from "./BaseService";
import {UserUrls} from "./urls";

class UsersService {
  static get_users = (range: Pagination) => {
    return BaseService.getRequest(UserUrls.LIST_USERS(range), true);
  };
  static add_user = (data: AddUser) => {
    return BaseService.postRequest(UserUrls.ADD_USER, data, true);
  };
  static update_user = (data: UpdateUser) => {
    return BaseService.putRequest(UserUrls.UPDATE_USER, data, true);
  };
  static download_user_csv = (public_id: string | number) => {
    return BaseService.getRequest(UserUrls.DOWNLOAD_USER_CSV(public_id), true);
  };
  static send_user_mail = (public_id: string | number) => {
    return BaseService.getRequest(UserUrls.SEND_MAIL_USER(public_id), true);
  };
  static delete_user = (public_id: string) => {
    return BaseService.deleteRequest(
      UserUrls.DELETE_USER(public_id),
      {public_id: public_id},
      true
    );
  };
  static get_recent_user = () => {
    return BaseService.getRequest(
      UserUrls.GET_RECENT_USER,
      true
    );
  };

  static get_user_stat = (public_id: string) => {
    return BaseService.getRequest(UserUrls.GET_USER_STAT(public_id), true)
  }

  static get_user_review = (public_id: string, page: number = 1) => {
    return BaseService.getRequest(UserUrls.GET_USER_REVIEW(public_id, page), true)
  }
}

export default UsersService;
