import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  CogIcon,
  BriefcaseIcon,
  CreditCardIcon,
  CollectionIcon,
  HomeIcon,
  DocumentReportIcon,
  CurrencyEuroIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  PresentationChartBarIcon,
  DatabaseIcon,
  CurrencyDollarIcon,
  AdjustmentsIcon,
  OfficeBuildingIcon
} from "@heroicons/react/outline";
import { matchPath, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/solid";

interface NavBarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
}

function NavBar(props: NavBarProps) {
  const { sidebarOpen, setSidebarOpen } = props;
  const [title, setTitle] = useState<string>("comii");
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    // document.title = title;
  });

  const navigation = [
    {
      name: "Comiii-Bordbuch",
      Link: "/app/home",
      icon: HomeIcon,
      current: !!matchPath(location.pathname, "/app/home"),
    },
    {
      name: "Statistiken",
      Link: "/app/statistiken",
      icon: PresentationChartBarIcon,
      current: !!matchPath(location.pathname, "/app/statistiken"),
    },
    {
      name: t("service_request"),
      Link: "/app/anfragen",
      icon: DatabaseIcon,
      current: !!matchPath(location.pathname, "/app/anfragen"),
    },
    /*{
      name: "KPI",
      Link: "/app/fakten",
      icon: AdjustmentsIcon,
      current: !!matchPath(location.pathname, "/app/fakten"),
    },*/

  ];

  navigation.forEach((element) => {
    if (element.current) {
      document.title = element.name;
    }
  });


  const souMenu = [
    {
      name: "Dienstleistungen",
      Link: "/app/services",
      icon: BriefcaseIcon,
      current: !!matchPath(location.pathname, "/app/services"),
    },
    {
      name: t("page_title_category"),
      Link: "/app/categories",
      icon: CollectionIcon,
      current: !!matchPath(location.pathname, "/app/categories"),
    },
    {
      name: t("page_title_command"),
      Link: "/app/Auftragsmanagement",
      icon: CollectionIcon,
      current: !!matchPath(location.pathname, "/app/Auftragsmanagement"),
    },
    {
      name: t("page_title_data"),
      Link: "/app/Stammdatenmanagement",
      icon: CollectionIcon,
      current: !!matchPath(location.pathname, "/app/Stammdatenmanagement"),
    },
    {
      name: t('user_page_title'),
      Link: "/app/anwender",
      icon: UserGroupIcon,
      current: !!matchPath(location.pathname, "/app/anwender"),
    },
    {
      name: t('institution_page_title'),
      Link: "/app/institutionen",
      icon: OfficeBuildingIcon,
      current: !!matchPath(location.pathname, "/app/institutionen"),
    },
  ];


  const souMenuBilling = [
    {
      name: "Bafin-Bericht",
      Link: "/app/bafin",
      icon: DocumentReportIcon,
      current: !!matchPath(location.pathname, "/app/bafin"),
    },
    {
      name: 'APP-Bankkonto',
      Link: "/app/app-bankkonto",
      icon: CurrencyEuroIcon,
      current: !!matchPath(location.pathname, "/app/app-bankkonto"),
    },
    {
      name: 'Treuhand-Bankkonto',
      Link: "/app/treuhand-bankkonto",
      icon: CurrencyEuroIcon,
      current: !!matchPath(location.pathname, "/app/treuhand-bankkonto"),
    }
  ];


  const [currentSubmenu, setCurrentSubmenu] = useState<string>(souMenu.findIndex(e => e.current) !== -1 ? "Einstellungen" : souMenuBilling.findIndex(e => e.current) !== -1 ? "Finanzen" : "")


  const secondaryNavigation = [
    { name: "Einstellungen", href: "#", icon: CogIcon, subMenu: souMenu },
    { name: "Finanzen", href: "#", icon: CurrencyDollarIcon, subMenu: souMenuBilling },
  ];



  const ThirdNavigation = [
    { name: "Hilfe", href: "#", icon: QuestionMarkCircleIcon },
    // { name: "Schutz Daten/DSGVO", href: "/app/schutz-daten", icon: ShieldCheckIcon },
  ]

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="mx-auto h-16 w-auto"
                  src={"/static/images/logo.png"}
                  alt="commi"
                />
              </div>
              <nav
                className="mt-5 flex-shrink-0 h-full divide-y divide-green-800 overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.Link}
                      className={clsx(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:text-white hover:bg-gray-700",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                      onClick={() => {
                        setTitle(item.name);
                      }}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <item.icon
                        className={clsx(
                          item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                          'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 pt-6">
                  <div className="px-2 space-y-1">
                    {secondaryNavigation.map((item) => (
                      <div key={item.name}>
                        <div className=" relative">
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={() => {
                              if (currentSubmenu === item.name)
                                setCurrentSubmenu("");
                              else
                                setCurrentSubmenu(item.name);
                            }}
                            className="w-full group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                          >
                            <item.icon
                              className="grow-0 text-gray-400 group-hover:text-gray-300 mr-4 flex-shrink-0 h-6 w-6"
                              aria-hidden="true"
                            />
                            <div className="grow w-full text-left">
                              {item.name}
                            </div>
                            <ChevronDownIcon
                              className="grow-0 hidden flex-shrink-0 ml-1 h-5 w-5 text-white lg:block"
                              aria-hidden="true"
                            />
                          </a>

                          <div className={`ml-2 mt-2 w-68 py-1 px-1 transition-all duration-500 ${currentSubmenu === item.name ? 'max-h-96' : 'max-h-0'} overflow-hidden`}>
                            {item.subMenu.map((element) => (
                              <a
                                key={element.name}
                                href={element.Link}
                                onClick={() => {
                                  setTitle(item.name);
                                }}
                                aria-current={element.current ? "page" : undefined}
                                className={clsx(
                                  element.current
                                    ? "bg-gray-900 text-white"
                                    : "text-gray-300 hover:text-white hover:bg-gray-700",
                                  "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                )}
                              >
                                <element.icon
                                  className={clsx(
                                    element.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                    'mr-4 flex-shrink-0 h-6 w-6'
                                  )}
                                  aria-hidden="true"
                                />
                                {element.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>

                    ))}
                    {ThirdNavigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={clsx(
                          false
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:text-white hover:bg-gray-700",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                        onClick={() => {
                          // setTitle(item.name);
                        }}
                        aria-current={false ? "page" : undefined}
                      >
                        <item.icon
                          className={clsx(
                            false ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </nav>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:w-70 lg:flex-col lg:fixed lg:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow bg-gray-800 pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img
              className="mx-auto h-16 w-auto"
              src={"/static/images/logo.png"}
              alt="commi"
            />
          </div>
          <nav
            className="mt-5 flex-1 flex flex-col divide-y overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="px-2 space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.Link}
                  className={clsx(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:text-white hover:bg-gray-700",
                    "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                  )}
                  onClick={() => {
                    setTitle(item.name);
                  }}
                  aria-current={item.current ? "page" : undefined}
                >
                  <item.icon
                    className={clsx(
                      item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                      'mr-4 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))}
            </div>
            <div className="mt-6 pt-6">
              <div className="px-2 space-y-1">
                {secondaryNavigation.map((item) => (
                  <div key={item.name}>
                    <div className=" relative">
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={() => {
                          if (currentSubmenu === item.name)
                            setCurrentSubmenu("");
                          else
                            setCurrentSubmenu(item.name);
                        }}
                        className="w-full group flex items-center px-2 py-2 text-base font-medium rounded-md text-gray-300 hover:text-white hover:bg-gray-700"
                      >
                        <item.icon
                          className="grow-0 text-gray-400 group-hover:text-gray-300 mr-4 flex-shrink-0 h-6 w-6"
                          aria-hidden="true"
                        />
                        <div className="grow w-full text-left">
                          {item.name}
                        </div>
                        <ChevronDownIcon
                          className="grow-0 hidden flex-shrink-0 ml-1 h-5 w-5 text-white lg:block"
                          aria-hidden="true"
                        />
                      </a>

                      <div className={`ml-2 mt-2 w-68 py-1 px-1 transition-all duration-500 ${currentSubmenu === item.name ? 'max-h-96' : 'max-h-0'} overflow-hidden`}>
                        {item.subMenu.map((element) => (
                          <a
                            key={element.name}
                            href={element.Link}
                            onClick={() => {
                              setTitle(item.name);
                            }}
                            aria-current={element.current ? "page" : undefined}
                            className={clsx(
                              element.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:text-white hover:bg-gray-700",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <element.icon
                              className={clsx(
                                element.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                'mr-4 flex-shrink-0 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            {element.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>

                ))}
                {ThirdNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={clsx(
                      false
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:text-white hover:bg-gray-700",
                      "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                    )}
                    onClick={() => {
                      // setTitle(item.name);
                    }}
                    aria-current={false ? "page" : undefined}
                  >
                    <item.icon
                      className={clsx(
                        false ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-4 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default NavBar;
