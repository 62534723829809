import {toast} from "react-toastify";
import {ThunkAction} from "redux-thunk";
import UsersService from "services/api/UsersService";
import {
  addUserAction,
  deleteUserAction,
  fetchUserRequest, getRecentUserAction,
  updateUserSuccess,
} from "store/actions";
import {
  AddUser,
  ApplicationAction,
  ApplicationState,
  DeleteUser, IndividualStatResponse,
  Pagination,
  UpdateUser,
  User, UserReviewResponse,
} from "store/types";
import {set} from "nprogress";

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const getUsersEffect =
  (range: Pagination, setLoading: Function): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      return UsersService.get_users(range)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(false);
            let {data, current_page, pages, total} = await res.json();
            return dispatch(fetchUserRequest(data, pages, total, current_page));
          } else {
            setLoading(false);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(err);
        });
    };

export const addUserEffect =
  (data: AddUser, setLoading: Function, closeModal: Function): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      console.log("dans le useEffect");
      return UsersService.add_user(data)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(false);
            closeModal();
            toast.success("created_succefuly");
            let response: User = await res.json();
            console.log("response: ", response);
            return dispatch(addUserAction(response));
          } else {
            setLoading(false);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(err);
        });
    };
export const updateUserEffect =
  (data: UpdateUser, setLoading: Function, closeModal: Function): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      return UsersService.update_user(data)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(false);
            closeModal();
            toast.success("updated_succefully");
            let response: User = await res.json();
            return dispatch(updateUserSuccess(response));
          } else {
            setLoading(false);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(err);
        });
    };


export const downloadUserCsvEffect =
  (public_id: string | number, setLoading: (p: 'csv' | undefined) => void): Effect =>
    async () => {
      setLoading('csv');
      return UsersService.download_user_csv(public_id)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(undefined);
            let {url} = await res.json();
            window.open(url, '_blanc');
          } else {
            let {detail, message} = await res.json();
            toast.error(detail || message);
          }
        })
        .catch((err: any) => {
          toast.error('Es ist momentan nicht möglich, den Server zu kontaktieren. Bitte versuchen Sie es später erneut')
        })
        .finally(() => setLoading(undefined))
    };


export const sendMailUserInfoEffect =
  (public_id: string | number, setLoading: (p: 'mail' | undefined) => void): Effect =>
    async () => {
      setLoading('mail');
      return UsersService.send_user_mail(public_id)
        .then(async (res: any) => {
          if (res.status === 200) {
            toast.success("Informationen zu diesem Benutzer in Ihrer E-Mail-Adresse senden.");
          } else {
            let {detail, message} = await res.json();
            toast.error(detail || message)
          }
        })
        .catch((err: any) => {
          toast.error('Es ist momentan nicht möglich, den Server zu kontaktieren. Bitte versuchen Sie es später erneut')
        })
        .finally(() => setLoading(undefined))
    };


export const deleteUserEffect =
  (public_id: string, setLoading: Function, closeModal: Function): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      console.log("dans le useEffect");
      return UsersService.delete_user(public_id)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(false);
            closeModal();
            toast.success("deleted_succefully");
            return dispatch(deleteUserAction(public_id));
          } else {
            setLoading(false);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(err);
        });
    };


export const getRecentUsersEffect = (setLoading: Function): Effect => async (dispatch, getState) => {
  setLoading(true);
  return UsersService.get_recent_user()
    .then(async (res: any) => {
      if (res.status === 200) {
        setLoading(false);
        let response: User[] = await res.json();
        return dispatch(getRecentUserAction(response));
      } else {
        setLoading(false);
      }
    })
    .catch((err: any) => {
      setLoading(false);
      console.log(err);
    });
}

export const getUserReviewsEffect = (
  payload: {
    public_id: string,
    page: number
  },
  setLoading: Function,
  onSuccess: (response: UserReviewResponse) => void
): Effect => async (dispatch, getState) => {
  setLoading(true);
  return UsersService.get_user_review(payload.public_id, payload.page)
    .then(async (res: any) => {
      if ([200, 201].includes(res.status)) {
        setLoading(false);
        const response: UserReviewResponse = await res.json();
        onSuccess(response);
      } else {
        setLoading(false);
      }
    })
    .catch((err: any) => {
      setLoading(false);
      console.log(err);
    });
}

export const getUserStatEffect = (
  public_id: string,
  setLoading: Function,
  onSuccess: (response: IndividualStatResponse) => void
): Effect => async (dispatch, getState) => {
  setLoading(true);
  return UsersService.get_user_stat(public_id)
    .then(async (res: any) => {
      if ([200, 201].includes(res.status)) {
        setLoading(false);
        const response: IndividualStatResponse = await res.json();
        onSuccess(response);
      } else {
        setLoading(false);
      }
    })
    .catch((err: any) => {
      setLoading(false);
    });
}
