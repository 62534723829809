import {GET_RECENT_INSTITUTION, GetRecentInstitutionAction, Institution} from "store/types";
import {
  FETCH_INSTITUTION_REQUEST,
  FetchInstitutionRequest,
  AddInstitutionAction,
  ADD_INSTITUTION_ACTION,
  UpdateInstitutionSuccess,
  UPDATE_INSTITUTION_SUCCESS,
  DeleteInstitutionAction,
  DELETE_INSTITUTION_ACTION
} from "store/types/InstitutionsType";

export const fetchInstitutionRequest = (institutions: Institution[], pages: number, total: number, page: number): FetchInstitutionRequest => ({
  type: FETCH_INSTITUTION_REQUEST,
  institutions: institutions,
  pages,
  total,
  page
})
export const addInstitutionAction = (institution: Institution): AddInstitutionAction => ({
  type: ADD_INSTITUTION_ACTION,
  institution: institution,
})
export const updateInstitutionSuccess = (institution: Institution): UpdateInstitutionSuccess => ({
  type: UPDATE_INSTITUTION_SUCCESS,
  institution: institution,
})
export const deleteInstitutionAction = (uuid: string): DeleteInstitutionAction => ({
  type: DELETE_INSTITUTION_ACTION,
  uuid: uuid,
})

export const getRecentInstitutionAction = (data: Institution[]): GetRecentInstitutionAction => ({
  type: GET_RECENT_INSTITUTION,
  data
})

