import {DELETE_USER_ACTION, Pagination} from "store/types";
import {RequestParameters} from "store/types/RequestType";
import config from "../../config";

// export const prefixer = 'http://cd2d-154-72-162-57.ngrok.io/api/v1/';
export const prefixer = config.api_url;

export const AuthUrls = {
  LOGINUSER: `${prefixer}authentification/login`,
  UPDATE_USER_INFO: `${prefixer}auth/update`,
  UPDATE_PASSWORD: `${prefixer}auth/update-password`,
  GET_CONNECTED_INFO: `${prefixer}auth/me`,
  LOGOUTUSER: (token: string)=> `${prefixer}authentification/logout?token=${token}`
};

export const UserUrls = {
  UPDATE_PROFILE: `${prefixer}users/update`,
  ADD_USER: `${prefixer}administration/users`,
  UPDATE_USER: `${prefixer}administration/users`,
  DOWNLOAD_USER_CSV: (public_id: string | number) => `${prefixer}administration/users/csv/${public_id}`,
  SEND_MAIL_USER: (public_id: string | number) => `${prefixer}administration/users/mail/${public_id}`,
  CHANGE_ROLE: (user_id: number) => `${prefixer}user/${user_id}`,
  RESET_PASSWORD: (user_id: number) => `${prefixer}user/reset/${user_id}`,
  LIST_USERS: (range: Pagination) =>
    `${prefixer}administration/users?page=${range?.page ?? 1}&per_page=${
      range?.per_page ?? 18
    }&keyword=${range.keyword ?? ""}${
      range.role_id != null ? "&role_id=" + range.role_id : ""
    }`,
  DELETE_USER: (public_id: string) =>
    `${prefixer}administration/users/${public_id}`,
  GET_RECENT_USER: `${prefixer}statistics/recent-user`,
  GET_USER_REVIEW: (user_public_id: string, page: number = 1) => `${prefixer}administration/user/reviews?page=${page}&per_page=5&user_public_id=${user_public_id}`,
  GET_USER_STAT: (user_public_id: string) => `${prefixer}administration/user/individual-stat?user_public_id=${user_public_id}`,
};

export const ServicesUrls = {
  LIST_SERVICES: (range: Pagination) => {
    return `${prefixer}administration/services?page=${range.pages}&per_page=${
      range.per_page
    }&keyword=${range.keyword ?? ""}`;
  },
  Add_SERVICE: `${prefixer}administration/services`,
  UPDATE_SERVICES: `${prefixer}administration/services`,
  DELETE_SERVICE: (id: number) => `${prefixer}administration/services/${id}`,
};

export const CategoryUrls = {
  LIST_CATEGORY: (range: Pagination) =>
    `${prefixer}administration/services/categories?page=${
      range.pages
    }&per_page=${range.per_page}&keyword=${range.keyword ?? ""}`,
  ADD_CATEGORY: `${prefixer}administration/services/categories`,
  UPDATE_CATEGORY: `${prefixer}administration/services/categories`,
  DELETE_CATEGORY: (id: number) =>
    `${prefixer}administration/services/categories/${id}`,
};

export const SummaryStatisticUrls = {
  LIST_SUMMARY: `${prefixer}statistics/summary`,
};

export const SummaryMonthStatisticUrls = {
  LIST_MONTH_SUMMARY: `${prefixer}statistics/month`,
};

export const SummaryMonthRequestStatisticUrls = {
  LIST_REQUEST_MONTH_SUMMARY: `${prefixer}statistics/month/requests`,
};

export const RequestUrls = {
  LIST_REQUEST: (range: RequestParameters) =>
    `${prefixer}administration/requests?page=${range.pages}&per_page=${
      range.per_page
    }${(range?.keyword ?? "") !== "" ? `&keyword=${range?.keyword}` : ""}
    ${
      range.order_field != "" ? `&order_field=${range.order_field}` : ""
    }
    ${
      range.service_finished != null
        ? `&service_finished=${range.service_finished}`
        : ""
    }
    ${range.start_date != "" ? `&start_date=${range.start_date}` : ""}
    ${range.end_date != "" ? `&end_date=${range.end_date}` : ""}${
      range.status != "" ? `&status=${range.status}` : ""
    }${(range?.service_id ?? 0) != 0 ? `&service_id=${range?.service_id ?? ""}` : ''}`,
  GET_RECENT_REQUEST: `${prefixer}statistics/recent-request`
};

export const RequestByCityUrls = {
  REQUEST_BY_CITY_URL: `${prefixer}statistics/city/requests`,
};

export const StatisticsUrl = {
  LIST_MOST_HOUR: `${prefixer}statistics/most_hours_services`,
  LIST_MOST_SERVICE_BY_MONTH: `${prefixer}statistics/services/turnover`,
  LIST_AMOUNT_SPEND_BY_SENIOR: `${prefixer}statistics/month/amount-spent`,
  GET_KPI_STATS: `${prefixer}statistics/kpi`,
  GET_BAFIN_STATS: `${prefixer}statistics/reporting/bafin`,
  FETCH_LOCALISATION: `${prefixer}statistics/users_by_city`,
  EXPORT_BAFIN_DATA: `${prefixer}users/transaction/export-data`,
};

export const InstitutionsUrls = {
  UPDATE_INSTITUTION: `${prefixer}administration/institutions`,
  ADD_INSTITUTION: `${prefixer}administration/institutions`,
  LIST_INSTITUTIONS: (range: Pagination) =>
    `${prefixer}administration/institutions?page=${range?.page ?? 1}&per_page=${
      range?.per_page ?? 18
    }&keyword=${range.keyword ?? ""}`,
  DELETE_INSTITUTION: (institution_uuid: string) =>
    `${prefixer}administration/institutions/${institution_uuid}`,
  DOMAIN_CHECKING:  `${prefixer}institution/domain/checking`,
  DOMAIN_UPDATE:  `${prefixer}institution/domain`,
};