
import { SUMMARY_REQUEST_MONTH_FAIL,SummaryRequestMonthState,SUMMARY_REQUEST_MONTH_SUCCESS,SUMMARY_REQUEST_MONTH_LOADING,SummaryRequestMonthFail,SummaryRequestMonthLoading,SummaryRequestMonthSuccess,SummaryRequestMonthType } from 'store/types/SummaryStatisticRequestMonth'

export const FetchRequestMonthSummary = () : SummaryRequestMonthLoading => (
    {
        type: SUMMARY_REQUEST_MONTH_LOADING,
    }
)
export const fetchSummaryRequestMonthSuccess = (loading: boolean, summary: SummaryRequestMonthType) : SummaryRequestMonthSuccess => (
    {
        type: SUMMARY_REQUEST_MONTH_SUCCESS,
        payload:summary,
        loading:loading,
    }
)
export const fetchMonthSummaryRequestError = () : SummaryRequestMonthFail => (
    {
        type: SUMMARY_REQUEST_MONTH_FAIL,
    }
)
