import { Pagination } from "store/types";
import BaseService from "./BaseService";
import {SummaryMonthRequestStatisticUrls} from './urls'

class SummaryMonthRequestStatistic {
    static get_summary = () => {
        return BaseService.getRequest(SummaryMonthRequestStatisticUrls.LIST_REQUEST_MONTH_SUMMARY, true);
      };
}

export default SummaryMonthRequestStatistic