import {Action} from "redux";
import {Address,} from "store/types";
import { Avatar, User } from "./AccountTypes";
import { Pagination } from "./UsersType";

export interface Institution{
  name: string,
  logo: Avatar,
  // contact_person_name: string,
  // contact_person_gender: string,
  // contact_person_email: string,
  uuid?: string,
  date_added?: string,
  date_modified?: string,
  user: User,
  secondary_color?: string,
  primary_color?: string,
  domain?: string,
  helfers?: User[],
}

export interface InstitutionsState {
  range: Pagination;
  data: Institution[];
  recent_institution: Institution[];
}

export interface UpdateInstitution {
  name?: string,
  logo?: string,
  contact_person_name?: string,
  contact_person_gender?: string,
  contact_person_email?: string,
  contact_person_lastname?: string,
  uuid?: string
  secondary_color?: string,
  primary_color?: string,
}
export interface UpdateInstitutionDomain {
  uuid: string
  domain: string,
}
export interface CheckInstitutionDomain {
  uuid: string
  domain?: string,
}

export const FETCH_INSTITUTION_REQUEST = "FETCH_INSTITUTION_REQUEST";
export const ADD_INSTITUTION_ACTION = "ADD_INSTITUTION_ACTION";
export const UPDATE_INSTITUTION_SUCCESS = "UPDATE_INSTITUTION_SUCCESS";
export const DELETE_INSTITUTION_ACTION = "DELETE_INSTITUTION_ACTION";
export const GET_RECENT_INSTITUTION = "GET_RECENT_INSTITUTION";

export interface FetchInstitutionRequest extends Action {
  type: typeof FETCH_INSTITUTION_REQUEST;
  institutions: Institution[];
  pages: number;
  total: number;
  page: number;
}

export interface AddInstitutionAction extends Action {
  type: typeof ADD_INSTITUTION_ACTION;
  institution: Institution;
}

export interface UpdateInstitutionSuccess extends Action {
  type: typeof UPDATE_INSTITUTION_SUCCESS;
  institution: Institution;
}

export interface DeleteInstitutionAction extends Action {
  type: typeof DELETE_INSTITUTION_ACTION;
  uuid: String;
}


export interface GetRecentInstitutionAction extends Action {
  type: typeof GET_RECENT_INSTITUTION;
  data: Institution[];
}
