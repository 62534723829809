import {
  ADD_INSTITUTION_ACTION,
  ApplicationAction,
  DELETE_INSTITUTION_ACTION,
  FETCH_INSTITUTION_REQUEST,
  UPDATE_INSTITUTION_SUCCESS,
  Institution,
  InstitutionsState,
  GET_RECENT_INSTITUTION
} from "store/types";
import produce from "immer";

const initialState: InstitutionsState = {
  range: {
    per_page: 18,
    pages: 1,
    page: 1,
    keyword: "",
  },
  data: [],
  recent_institution: []
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case FETCH_INSTITUTION_REQUEST:
      return produce(state, (draft) => {
        draft.data = action.institutions;
        draft.range.pages = action.pages;
        draft.range.page = action.page;
        draft.range.total = action.total;
      });
    case ADD_INSTITUTION_ACTION:
      return produce(state, (draft) => {
        const institutions: Institution[] = [...state.data];
        institutions.push(action.institution);
        draft.data = institutions;
      });
    case UPDATE_INSTITUTION_SUCCESS:
      return produce(state, (draft) => {
        draft.data = [...state.data].map((institution) => {
          if (institution.uuid == action.institution.uuid) {
            return action.institution;
          } else {
            return institution;
          }
        });
      });
    case DELETE_INSTITUTION_ACTION:
      return produce(state, (draft) => {
        let institutions: Institution[] = [...state.data];
        institutions = institutions.filter((institution) => {
          return institution.uuid != action.uuid;
        });
        draft.data = institutions;
      });
    case GET_RECENT_INSTITUTION:
      return produce(state, (draft) => {
        draft.recent_institution = action.data;
      });
    default: {
      return state;
    }
  }
};

export default reducer;
