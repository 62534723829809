import { mocked } from 'utils';
import { auth_users, connected_information } from 'services/fixtures';

export const login_mock = (info: any) => {
  console.log('info::', info);
  console.log('info::', auth_users);
  const { email, password } = info;
  let auth_user = auth_users.find(
    (auth_user) =>
      auth_user.email === email && auth_user.password === password
  );
  console.log({auth_user})
  return auth_user
    ? mocked(200, {
        token: {access_token: auth_user.Authorization},
        user: auth_user.data,
        message: auth_user.message
      })
    : mocked(401, { message: 'Login oder Passwort ungültig!' });
};

export const register_mock = (info: any) => {
  let data_is_valid =
    info.name && info.code_uai && info.academie_id >= 0 && info.referer;
  let school = {
    name: 'School Test',
    email: 'test@gmail.com',
    qualite: 'conseiller',
    phonenumber: '22415234',
    code_uai: '01020304'
  };

  if (!data_is_valid)
    return mocked(400, { message: 'Ungültiges Datenformat!' });
  else {
    let conflict =
      info.name === school.name ||
      info.referer.email === school.email ||
      info.code_uai === school.code_uai;

    return !conflict
      ? mocked(200, {
          message:
            "Wir haben Ihre Registrierungsanfrage erhalten, überprüfen Sie Ihr Postfach auf Ihr temporäres Passwort!"
        })
      : mocked(400, { message: 'Ein Benutzer mit dieser E-Mail existiert bereits' });
  }
};

export const update_password_mock = (info: any) => {
  if (info.current_password !== 'string')
    return mocked(400, {
      status: 'fail',
      message: 'Falsches altes Passwort'
    });
  else {
    return mocked(200, {
      message: 'Passwort erfolgreich geändert!',
      status: 'success'
    });
  }
};

export const initialize_reset_password = () => {
  return mocked(200, {
    status: 'success',
    message:
      'Konsultieren Sie Ihre Mailbox, um mit dem Zurücksetzen Ihres Passworts fortzufahren'
  });
};

export const confirm_reset_password = () => {
  return mocked(200, {
    status: 'success',
    message:
      'Ihre Anfrage zur Passwortänderung ist jetzt wirksam'
  });
};

export const get_connected_user_info = () => {
  return mocked(200, connected_information);
};
