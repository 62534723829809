import { ThunkAction } from 'redux-thunk';
import { ApplicationAction, ApplicationState } from 'store/types';
import { loginSuccess } from 'store/actions';
import { AuthService } from 'services/api';
import { loginError, loginRequest } from 'store/actions/AccountActions';
import { toast } from 'react-toastify';

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const getUserInfo = (): Effect => async (dispatch, getState) => {
    const account = getState().account
    return AuthService.get_userInfo()
        .then(async (res: any) => {
            if (res.status === 200) {
                let { user } = await res.json();
                return dispatch(loginSuccess({ ...account.user, ...user }, account.token || ''));
            }
        })
        .catch((err: any) => {
            console.log(err);
        });
};

export const updateUserInfoEffect = (
    payload: any,
    setLoading: (loading: boolean) => void,
    enqueueSnackbar: Function
)
    : Effect => async (dispatch, getState) => {
        const { account, i18n: { t = (key: string) => key } } = getState();
        setLoading(true);
        return AuthService.update_userInfo(payload)
            .then(async (res: any) => {
                const status = res.status === 200 || res.status === 201;
                let { user, message } = await res.json();
                enqueueSnackbar(message, {
                    variant: status ? 'success' : 'warning'
                });
                if (status) {
                    return dispatch(loginSuccess({ ...account.user, ...user }, account.token || ''));
                }
            })
            .catch((err: any) => {
                enqueueSnackbar(t('shared.internet_connexion_error'), {
                    variant: 'error'
                });
            })
            .finally(() => setLoading(false))
    };


export const updatePasswordEffect = (
    payload: any,
    setLoading: (loading: boolean) => void,
    onSuccess: () => void,
    enqueueSnackbar: Function
)
    : Effect => async (dispatch, getState) => {
        const { i18n: { t = (key: string) => key } } = getState();
        setLoading(true);
        return AuthService.update_password(payload)
            .then(async (res: any) => {
                const status = res.status === 200 || res.status === 201;
                let { message } = await res.json();
                if (status) onSuccess()
                enqueueSnackbar(message, {
                    variant: status ? 'success' : 'warning'
                });
            })
            .catch((err: any) => {
                enqueueSnackbar(t('shared.internet_connexion_error'), {
                    variant: 'error'
                });
            })
            .finally(() => setLoading(false))
    };


export const loginEffect = (
    email: string,
    password: string,
    onError: (msg: string) => void,
    onSuccess?: () => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    dispatch(loginRequest());
    return AuthService.login({ email_or_phonenumber: email, password })
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                let {
                    user,
                    token
                } = await res.json();
                if (onSuccess)
                    onSuccess();
                return dispatch(loginSuccess(user, token.access_token));
            } else {
                let { detail } = await res.json();
                onError(detail);
                dispatch(loginError(detail));
            }
        })
        .catch((err: any) => {
            console.log({ err })
            onError(t('shared.internet_connexion_error'))
            return dispatch(loginError(t('shared.internet_connexion_error')));
        })
};



export const logoutEffect = (
    onError: (msg: string) => void,
    onSuccess?: () => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key },
        account: { token }
    }: ApplicationState = getState();
    // dispatch(loginRequest());
    return AuthService.logout(String(token))
        .then(async (res: any) => {
            console.log(res)
            let { detail } = await res.json();
            if (detail) {
                toast(detail);
            }
            window.localStorage.removeItem("token")
            if (onSuccess)
                onSuccess();
            // dispatch(loginError(detail));
        })
        .catch((err: any) => {
            console.log({ err })
            onError(t('shared.internet_connexion_error'))
            // return dispatch(loginError(t('shared.internet_connexion_error')));
        })
};

