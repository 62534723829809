export default {
  translation: {
    add_user: "Nutzer hinzufügen",
    login_form_email: "Email",
    login_form_firstname: "Vorname",
    login_form_lastname: "Nachname",
    login_form_password: "Passwort",
    dashboard: "Armaturenbrett",
    user_title_page: "Benutzer",
    categorie_title_page: "Kategorie",
    add_category_button: "Kategorie hinzufügen",
    numbers_of_users: "Anzahl der Nutzer",
    view_all: "Alles sehen",
    most_wanted_service: "Am häufigsten nachgefragte Dienste",
    tab_rank: "Rang",
    tab_service_name: "Dienstname",
    tab_demand_number: "Anzahl der Anfragen",
    page_title_category: "Kategorien Dienstleistungen",
    page_title_command: "Auftragsmanagement",
    page_title_data: "Stammdatenmanagement",
    KLEIN_UNTERNEHMER: "Klein Unternehmer",
    GEWERBE: "Gewerbe",
    MITARBEITER: "Mitarbeiter",
    EHRENAMTLICHE_ALLTAGSHELFER: "Ehrenamtliche Alltagshelfer",
    street_nr: "Nr.",
    town: "Nr.",
    postal_code: "Postleitzahl",
    more_infos: "Mehr Informationen",
    location: "Standort",
    stats_amount_spend_by_seniors_by_month: "Monatliche Ausgabenstatistik für Senioren",
    tab_turnover: "Umsatz",
    most_profitable_service: "Die profitabelsten Dienste",
    most_profitable_hour: "Die Stunden mit den meisten Serviceanfragen",
    tab_hour: "Zeit",
    service_request: "Anfragen",
    CANCELLED: "Abgesagt",
    PENDING: "Offen",
    PENDING_HELFER: "Offen",
    OUTSTANDING: "Im Gange",
    COMPLETED: "Fertig",
    REJECTED_BY_ALL: "Von allen abgelehnt",
    EXPIRED: "Erlöschen",
    PAUSED: "Pausiert",
    PAID: "Bezahlt",
    FAILDED: "Scheitern",
    ALL: "Alle",
    last_week: "Letzte Woche",
    last_two_week: "Die letzten 14 Tage",
    last_month: "Letzter Monat",
    last_year: "Das letzte Jahr",
    this_year: "In diesem Jahr",
    total: "Gesamt",
    "shared.internet_connexion_error": "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut",
    ANDERE: "Andere",
    FRAU: "Frau",
    HERR: "Herr",
    gender_female: "Weiblich",
    gender_male: "Männlich",
    gender_other: "Sonstiges",
    user_page_title: "Anwender",
    category_page_title: "Kategorien Dienstleistungen",
    category_page_category_name: "Kategorie",
    add_category: "Kategorie hinzufügen",
    update_category: "Kategorie aktualisieren",
    delete_category: "Kategorie löschen",
    delete_category_alert_message: "Durch das Löschen einer Kategorie wird der Dienst dauerhaft von Ihrem System entfernt",
    delete_category_okay: "Ja, Dienst löschen",
    delete_category_none: "Nein, Kategorie beibehalten",
    institution_page_title: "Institutionen",
    add_institution: "Eine Institution hinzufügen",
    update_institution: "Eine Institution aktualisieren",
    delete_institution: "Diese Institution entfernen",
    delete_institution_none: "Instiitution behalten",
    delete_institution_alert_message: "Das Löschen des Intitutions wird diese komplett von Ihrem System entfernen",
    institution_name: "Name der Institutions",
    institution_logo: "Logo",
    institution_contact_name: "Name des Kontakt",
    institution_contact_email: "E-Mail des Kontakts",
    institution_contact_genre: "Geschlecht des Kontakts",
    bafin_bericht_page_title: "Bafin-Bericht",
    bafin_bericht_salary: "Gehalt",
    login_page_title: "Melden Sie sich bei Ihrem Konto an",
    login_form_name: "Name",
    login_form_login_button: "Einloggen",
    forgotten_password: "Passwort vergessen?",
    field_required: "Pflichtfeld",
    contact_person_email: "E-Mail des Kontakts",
    contact_person_gender: "Geschlecht des Kontakts",
    contact_person_name: "Name des Kontakt",
    form_input_change: "Change",
    global_action_add: "Hinzufügen",
    global_action_cancel: "Abbrechen",
    global_action_close: "Close",
    global_action_change: "Ändern",
    global_action_update: "Update",
    global_action_previous: "Vorherige",
    global_action_next: "Nächste",
    global_action_last_update: "Letzte Aktulisierung",
    global_action_try_again: "Versuchen Sie es nochmal",
    global_label_action: 'Aktion',
    global_label_title: 'Titel',
    global_label_export: "Exportieren",
    global_label_date: "Datum",
    global_label_status: "Status",
    global_label_created_by: "Erstellen von",
    global_label_user_online: "Online",
    global_label_registration_at: "Anmeldung um",
    home_page_last_services: "Letzte Aufrage",
    service_name: "Servicename",
    institution_created_successfully: "Ihre Institution wurde erfolgreich erstellt",
    institution_updated_successfully: "Ihre Institution wurde erfolgreich aktualisiert",
    institution_primary_color: "Primärfarbe",
    institution_secondary_color: "Sekundärfarbe",
    institution_informations: "Informationen",
    institution_domain_setting: "Einstellung der Subdomäne",
    institution_domain_suggestion: "Subdomänevorschläge",
    institution_domain_name: "Name der Subdomäne",
    institution_domain_helfers: "Helfers",
    user_page_gender: "Geschlecht",
    user_page_birthday: "Geburtsdatum",
  },
};
