import { Pagination, UpdateInstitution, Institution, UpdateInstitutionDomain, CheckInstitutionDomain } from "store/types";
import BaseService from "./BaseService";
import { InstitutionsUrls } from "./urls";

class InstitutionsService {
  static get_institutions = (range: Pagination) => {
    return BaseService.getRequest(InstitutionsUrls.LIST_INSTITUTIONS(range), true);
  };
  static add_institution = (data: UpdateInstitution) => {
    return BaseService.postRequest(InstitutionsUrls.ADD_INSTITUTION, data, true);
  };
  static update_institution = (data: UpdateInstitution) => {
    return BaseService.putRequest(InstitutionsUrls.UPDATE_INSTITUTION, data, true);
  };
  static check_domain = (data: CheckInstitutionDomain) => {
    return BaseService.postRequest(InstitutionsUrls.DOMAIN_CHECKING, data, true);
  };
  static update_domain = (data: UpdateInstitution) => {
    return BaseService.putRequest(InstitutionsUrls.DOMAIN_UPDATE, data, true);
  };
  static delete_institution = (uuid: string) => {1
    return BaseService.deleteRequest(
      InstitutionsUrls.DELETE_INSTITUTION(uuid),
      { uuid },
      true
    );
  };
}

export default InstitutionsService;
