import { User } from "store/types";

interface AuthResults {
  email: string;
  password: string;
  Authorization: string;
  message: string;
  data: User;
}

export const connected_user: User = {
  email: "kevin.wamba@kevmax.com",
  firstname: "Kevin",
  lastname: "WAMBA",
  phonenumber: "",
  status: "ACTIVED",
  id: 51,
  birthday: null,
  public_id: "5240a850-5c99-46c9-b5c5-a4ca7e95cb8d",
  address: null,
  avatar: null,
  account_type: "ALS_KLEINGEWERBE",
  date_added: "2021-11-04T14:31:12.991910",
  date_modified: "2021-11-04T14:31:12.991910",
  role: { id: 1, title: "Administrator" },
  gender: "M",
  payment_infos: [],
  documents: [],
};

export const auth_users: AuthResults[] = [
  {
    Authorization:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjE1ODQyNDEsImlhdCI6MTU5MDA0ODIzNiwic3ViIjoxfQ.Xvo-hJuQyz6I5-Co7Ezs3LFWUYjGmGTTlon1Bm7ANKk",
    message: "Connexion reussi",
    email: "admin@gmail.com",
    password: "string",
    data: connected_user,
  },
];

export const list_users: User[] = [
  {
    email: "kevin.wamba@kevmax.com",
    firstname: "Kevin",
    lastname: "WAMBA",
    phonenumber: "",
    status: "ACTIVED",
    id: 51,
    birthday: null,
    documents: [],
    public_id: "5240a850-5c99-46c9-b5c5-a4ca7e95cb8d",
    address: null,
    avatar: null,
    account_type: "ALS_KLEINGEWERBE",
    date_added: "2021-11-04T14:31:12.991910",
    date_modified: "2021-11-04T14:31:12.991910",
    role: { id: 1, title: "Administrator" },
    gender: "M",
    payment_infos: [],
  },
];

export const connected_information = {
  status: "success",
  message: "Connecté(e) avec succès.",
  Authorization:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjcwNDg3MjYsImlhdCI6MTU5NTUxMjcyMSwic3ViIjoxLCJwdWJsaWNfaWQiOiJmOTA0ZGMxNS1hMjU1LTQxNjMtYWRjMS0xYTUzYmMwZjZlMjIifQ.SJpeeBxPq4cTEh-v13cCVYM-MnUDoKjj1cwicxDJNCM",
  data: {
    user: {
      email: "noumbissipatrick3@gmail.com",
      phonenumber: "675897548",
      firstname: "du pont",
      is_password_changed: true,
      role: "conseiller",
      public_id: "f904dc15-a255-4163-adc1-1a53bc0f6e22",
    },
    school: {
      code_uai: "01020304",
      presentation:
        "<h4>Le Lorem Ipsum&nbsp;</h4><p>est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme as</p>",
      email_racine: "@gmail.com",
      id: 1,
      name: "Lycée Robert Doisneau",
      contact: "0693948576",
      address: "Route du louvre",
      type: {
        id: 7,
        title: "Centre de formation et d'apprentis (CFA)",
      },
      logo_url:
        "https://files.youmoove-france.com/production/73d6f722-a67c-11ea-896a-02420a000a58-WhatsApp%20Image%202020-05-22%20at%2015.08.15.jpeg",
      times: "lundi: 09h00 - 17h00",
      academie: {
        id: 4,
        title: "Académie de Bordeaux",
      },
      postal_code: "44000",
      statut: {
        id: 2,
        title: "Privé",
      },
      referencement_paid: true,
      banner_url:
        "https://files.youmoove-france.com/production/643ea25a-a673-11ea-b460-02420a000a58-WhatsApp%20Image%202020-05-22%20at%2021.01.25.jpeg",
      university_or_group: null,
      date_modified: "2020-06-19T10:40:08.341773+00:00",
      pricing_plan: {
        start_date: "2019-11-19T00:00:00+00:00",
        end_date: "2020-11-18T00:00:00+00:00",
        pricing_id: 2,
        code_number: 3000,
        used_code_number: 42,
      },
      university_or_group_available: null,
      city: "Nantes",
      manage_by: {
        email: "noumbissipatrick3@gmail.com",
        phonenumber: "675897548",
        lastname: "",
        firstname: "du pont",
        role: "conseiller",
      },
      is_active: true,
      date_added: "2019-11-12T09:48:13.134984+00:00",
    },
  },
};
