import { t } from "i18next";
import { toast } from "react-toastify";
import { ThunkAction } from "redux-thunk";
import InstitutionsService from "services/api/InstitutionsService";
import {
  addInstitutionAction,
  deleteInstitutionAction,
  fetchInstitutionRequest,
  updateInstitutionSuccess,
} from "store/actions";
import {
  ApplicationAction,
  ApplicationState,
  Pagination,
  UpdateInstitution,
  Institution,
  UpdateInstitutionDomain,
} from "store/types";

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const getInstitutionsEffect =
  (range: Pagination, setLoading: Function, onError: (msg: string) => void): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      return InstitutionsService.get_institutions(range)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(false);
            let { data, current_page, pages, total } = await res.json();
            return dispatch(fetchInstitutionRequest(data, pages, total, current_page));
          } else {
            const { detail } = await res.json()
            toast.warning(detail)
            setLoading(false);
          }
        })
        .catch((err: any) => {
          onError('shared.internet_connexion_error')
          setLoading(false);
          console.log(err);
        });
    };

export const addInstitutionEffect =
  (data: UpdateInstitution, setLoading: Function, onSuccess: (msg: string) => void, onError: (msg: string) => void): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      return InstitutionsService.add_institution(data)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(false);
            onSuccess('');
            let response: Institution = await res.json();
            return dispatch(addInstitutionAction(response));
          } else {
            const { detail } = await res.json()
            toast.warning(detail)
            setLoading(false);
          }
        })
        .catch((err: any) => {
          onError('shared.internet_connexion_error')
          setLoading(false);
          console.log(err);
        });
    };

export const updateInstitutionEffect =
  (data: UpdateInstitution, setLoading: Function, onSuccess: (msg: string) => void, onError: (msg: string) => void): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      return InstitutionsService.update_institution(data)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(false);
            onSuccess('');
            let response: Institution = await res.json();
            return dispatch(updateInstitutionSuccess(response));
          } else {
            const { detail } = await res.json()
            toast.warning(detail)
            setLoading(false);
          }
        })
        .catch((err: any) => {
          onError('shared.internet_connexion_error')
          setLoading(false);
          console.log(err);
        });
    };

export const updateInstitutionDomainEffect =
  (data: UpdateInstitutionDomain, setLoading: Function, onSuccess: (msg: string) => void, onError: (msg: string) => void): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      return InstitutionsService.update_domain(data)
        .then(async (res: any) => {
          if (res.status === 200) {
            setLoading(false);
            onSuccess('');
            let response: Institution = await res.json();
            return dispatch(updateInstitutionSuccess(response));
          } else {
            const { detail } = await res.json()
            toast.warning(detail)
            setLoading(false);
          }
        })
        .catch((err: any) => {
          onError('shared.internet_connexion_error')
          setLoading(false);
          console.log(err);
        });
    };

export const deleteInstitutionEffect =
  (uuid: string, setLoading: Function, onSuccess: (msg: string) => void, onError: (msg: string) => void): Effect =>
    async (dispatch, getState) => {
      const account = getState().account;
      setLoading(true);
      return InstitutionsService.delete_institution(uuid)
        .then(async (res: any) => {
          if (res.status === 200) {
            const { message } = await res.json()
            setLoading(false);
            onSuccess(message ?? '');
            return dispatch(deleteInstitutionAction(uuid));
          } else {
            const { detail } = await res.json()
            toast.warning(detail)
            setLoading(false);
          }
        })
        .catch((err: any) => {
          onError('shared.internet_connexion_error')
          setLoading(false);
          console.log(err);
        });
    };

